<!-- This page should be rendered on admin plugin only -->

<template>
  <SubHeader>
    <template #title>Standard</template>
    <template #description>
      Like all your ducks in a row? Then the grid layout is right up your alley.
      A fully responsive grid layout with customizable rows and columns to fit
      whatever you throw at it – whether it be a narrow sidebar, wide footer, or
      full page gallery – grid layout has your back.
    </template>
  </SubHeader>
  <!-- HashSearchBar is only available on the business account type -->
  <!-- <HashtagSearchBar
    v-if="dataStore.userAuthType === 'business'"
  ></HashtagSearchBar> -->
  <div class="setting">
    <div>
      <label for="columns">Number of columns:</label>
      <prime-dropdown
        :loading="dataStore.mediaArrayLoading"
        inputId="columns"
        v-model="dataStore.settings.columns"
        :options="columnsOptions"
        optionLabel="value"
        optionValue="value"
        placeholder="Select number of columns"
        class="w-full md:w-14rem"
      />
    </div>
    <div>
      <label for="rows">Number of rows:</label>
      <prime-dropdown
        :loading="dataStore.mediaArrayLoading"
        inputId="rows"
        v-model="dataStore.settings.rows"
        :options="rowsOptions"
        optionLabel="value"
        optionValue="value"
        placeholder="Select number of rows"
        class="w-full md:w-14rem"
      />
    </div>
  </div>
</template>

<script setup>
import SubHeader from "@/components/UI/SubHeader.vue";
// import HashtagSearchBar from "@/components/HashtagSearchBar.vue";

import { onUnmounted, ref, watch } from "vue";

import appDataStore from "@/stores/appDataStore";
const dataStore = appDataStore();
const rowsOptions = ref([
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
]);
const columnsOptions = ref([{ value: 2 }, { value: 3 }, { value: 4 }]);
// reset settings
onUnmounted(() => {
  dataStore.setSettings({ columns: 4, rows: 2 });
});

// watch if user changed layout settings and hence save setting in server.
// and also get the new data based on settings.
watch(
  () => [dataStore.settings.columns, dataStore.settings.rows],
  (newValue) => {
    const [newColumnsValue, newRowsValue] = newValue;

    dataStore.saveSettingsInServer({
      length: newColumnsValue,
      width: newRowsValue,
    });
    dataStore.getMediaData(
      `https://ipfeed-api.numinix.com/api/return-account-type-data?limit=${
        newRowsValue * newColumnsValue
      }`,
      "POST"
    );
  }
);
</script>

<style lang="scss" scoped>
.setting {
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
</style>
