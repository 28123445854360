<template>
  <div class="template-container" v-if="!dataStore.mediaArrayLoading">
    <Carousel
      v-for="(array, index) in dataArrays"
      :key="`${dataStore.settings.columns}-${dataStore.settings.rows}-${index}`"
      :value="array"
      :numVisible="dataStore.settings.columns"
      :numScroll="dataStore.settings.columns"
      :circular="true"
    >
      <template #item="slotProps">
        <ImageCard :mediaData="slotProps.data" @show-modal="showModal" />
      </template>
    </Carousel>
  </div>
  <div
    v-else
    style="
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      justify-content: center;
      align-items: center;
    "
  >
    <Skeleton
      width="95%"
      height="18.75rem"
      v-for="num in dataStore.settings.rows"
      :key="num"
    ></Skeleton>
  </div>

  <ModalOverlay v-if="modalVisibility" :hideModalFn="hideModal">
    <MediaDetails :mediaData="curentModalData" :hideModalFn="hideModal" />
  </ModalOverlay>
</template>

<script setup>
import helpers from "@/services/helpers";
import { ref, computed } from "vue";
import Carousel from "primevue/carousel";
import MediaDetails from "../MediaDetails.vue";
import ModalOverlay from "../UI/ModalOverlay.vue";
import Skeleton from "primevue/skeleton";
import appDataStore from "@/stores/appDataStore";

const dataStore = appDataStore(),
  // modal states
  curentModalData = ref(null),
  modalVisibility = ref(false);

console.log("dataStore", dataStore);

const showModal = (payload) => {
  curentModalData.value = payload;
  modalVisibility.value = true;
};
const hideModal = () => {
  curentModalData.value = null;
  modalVisibility.value = false;
};

console.log("dataStore.mediaArray", dataStore.mediaArray);

const dataArrays = computed(() => {
  return helpers.arraySpliter(dataStore.mediaArray, dataStore.settings.rows);
});

const testArrays = [
  {
    id: "18300331594145402",
    username: "fernandotoressmercedes",
    timestamp: "2024-02-19",
    caption: "ew3a el 2atr ya 3am el 7ag",
    media_url:
      "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/428498703_408604561846350_1023730417328203804_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=R_jBS7LuCDYQ7kNvgFjJKTx&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBua_YAmkdaTr5sgt7tDoL1813y4dZHlCgfDO4BnWQPEg&oe=66AEAF26",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/C3hpDjIqAdo/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17871632183944796",
    username: "fernandotoressmercedes",
    timestamp: "2023-07-24",
    caption:
      "These blooming blossom trees with pink flowering branches are a sight to behold and fill hearts with joy.🍒\n#nature\n#pink_tree",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/362361035_853857722893323_7232506524180033750_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=0hp-_40dOmcQ7kNvgFO4ZfE&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYClnTw2qI1jSxBa3DpH9ejZ9JBTFNkjof0phRhPBla0rQ&oe=66AE963C",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/CvFIVDgLswT/",
    children: false,
    like_count: 1,
    comments_count: 0,
  },
  {
    id: "17986704575235124",
    username: "fernandotoressmercedes",
    timestamp: "2023-07-19",
    caption:
      "Drone footage for one of the great pyramids ❤️🇪🇬🇪🇬.\n#egyptomania\n#pyramid \n#dronephotography",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/o1/v/t16/f1/m82/D048C3485BED2576AA0BE1D45D1A2B95_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzMuNzIwLmRhc2hfYmFzZWxpbmVfMV92MSJ9&_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=104&vs=840411714084221_3844223890&_nc_vs=HBkcFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9EMDQ4QzM0ODVCRUQyNTc2QUEwQkUxRDQ1RDFBMkI5NV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACaSwtqz2L7oPxUCKAJDMywXQCwAAAAAAAAYEmRhc2hfYmFzZWxpbmVfMV92MREAdQAA&ccb=9-4&oh=00_AYB-SksO8G9p8Q2VZHlrcAB2YyZWRYJAtvc79l0QspktfA&oe=66AAC4CC&_nc_sid=1d576d",
    thumbnail_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.36329-15/361298370_1072651083707215_8319310748847602645_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=jeCXYkzkCHoQ7kNvgH8d1mZ&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYAa4rQ5owHRFd6a75Q5AqhAEWNaVzfdTajTNFvUY_rUGg&oe=66AEB1AD",
    media_type: "VIDEO",
    permalink: "https://www.instagram.com/reel/Cu4FQ_RIx1O/",
    children: false,
    like_count: 1,
    comments_count: 0,
  },
  {
    id: "18020271298614120",
    username: "fernandotoressmercedes",
    timestamp: "2023-07-14",
    caption: "skyscrapers",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/359806449_1334183530501190_6753655912570350542_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=q-yhswa49-wQ7kNvgEC1dWc&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCD4GC6x61XUA1GLt0Z00InWIEi38vGWfDCVj2FVScTkQ&oe=66AEC0D3",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/CurXE79rwdG/",
    children: false,
    like_count: 1,
    comments_count: 0,
  },
  {
    id: "18089626447344794",
    username: "fernandotoressmercedes",
    timestamp: "2023-06-21",
    caption:
      "If you love nature, you will find beauty anywhere.\nAdopt the pace of nature.\nThere's no Wi-Fi in the forest, but I promise you'll find a better connection.\nThe call of the wild is loud and true.",
    media_url:
      "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/355385874_658651563118277_1163915602900543317_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=OgBYQH_HGjMQ7kNvgHfr1G5&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCgY5odKkPAAVIynSu0Jxi2g5WAv2fGjyXMF7rtd2Uyaw&oe=66AEA24F",
    thumbnail_url: false,
    media_type: "CAROUSEL_ALBUM",
    permalink: "https://www.instagram.com/p/Ctv92TQL7k4/",
    children: {
      data: [
        {
          id: "18001210627743386",
          media_url:
            "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/355385874_658651563118277_1163915602900543317_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=OgBYQH_HGjMQ7kNvgHfr1G5&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCgY5odKkPAAVIynSu0Jxi2g5WAv2fGjyXMF7rtd2Uyaw&oe=66AEA24F",
          media_type: "IMAGE",
        },
        {
          id: "18024380263509166",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/354922731_1468847700590711_7910149129487325545_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=18de74&_nc_ohc=bz4Nr4y4oXkQ7kNvgEcRv80&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYAxw35gMT2l0bJxjeuTfz5HRM5ZafC5xEZfDXwJ_oIDgg&oe=66AEC7BD",
          media_type: "IMAGE",
        },
        {
          id: "17966093903525960",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/355392732_1447027336078771_9017868557946192745_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=hnJ0sVQTsDkQ7kNvgGrOTL2&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBUoFWbrxOF3vHBh6Ju7vD20snd2a0Y1NqRZQXbzbtuBw&oe=66AEBB7B",
          media_type: "IMAGE",
        },
        {
          id: "17958268532446813",
          media_url:
            "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/355103428_263875146222861_7249456257218171600_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=QeqoRP6wTHsQ7kNvgFgM7a3&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCUI68vDikO2g_ySNULaUzigMVWth6tGk35DtBiEy7MiA&oe=66AEB78D",
          media_type: "IMAGE",
        },
        {
          id: "18274825366124969",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/355077500_971678397296083_2227289933236733060_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=jcwPiZZGLS0Q7kNvgGvo8aY&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBXTTHABxoCO6HfoFB1Ibc-YzGKLqr04hmVMfbWfncsuw&oe=66AEA6A0",
          media_type: "IMAGE",
        },
        {
          id: "17962963961367976",
          media_url:
            "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/355052974_836802901260224_8604101673550835488_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=PmeE7JCmXjcQ7kNvgFj3Anv&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBR7lU2mMS2EF3GJQX905knH6hOUksgFxtWSHZIxUyaqw&oe=66AEAE66",
          media_type: "IMAGE",
        },
      ],
    },
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17976073505346034",
    username: "fernandotoressmercedes",
    timestamp: "2023-06-21",
    caption:
      "“It’s the rain that fills the rivers, not the dew.” 💦💦💦💦\n\n“Don’t confuse your path with your destination. Just because it’s stormy now doesn’t mean that you aren’t headed for sunshine.”\n\n“Because rain will wash away everything, if you let it.” \n\n“Without rain, there is no life.”",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/355409734_593194989614449_2749272377394497324_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=8vTBPEQRpIMQ7kNvgFjY07M&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&gid=AGk4yf2JUIz1DxMUlc-cgbQ&oh=00_AYCJrb27l8MkEsZ_Z1ra-EUusm3xiyC39QlpEYA7_RkXiQ&oe=66AEABDF",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/CtvoyQlLWbs/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17849393699979767",
    username: "fernandotoressmercedes",
    timestamp: "2023-06-20",
    caption: "It is Summer time 🏊‍♂️🌞",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/355064270_770410211249539_862204717622050325_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=wk9TOenYe3MQ7kNvgEXeo5n&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYAw8ca12sW1oPSySY7Lue5AwXmqtoCQyAERS1Ur81ytSQ&oe=66AEC78E",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/Cttddb1LPmt/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17886858443852263",
    username: "fernandotoressmercedes",
    timestamp: "2023-06-20",
    caption: "wonderful universe 🌐",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/355109189_282819997568995_4897727357366181873_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=18de74&_nc_ohc=d9OYUeF2zw0Q7kNvgE_jgHa&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCdUDvXa3lgwFEs9bPjZouOV3YzolRhOjAYe3pI-OoA6g&oe=66AE9BF0",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/CttdSUZraRM/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "18004446424840193",
    username: "fernandotoressmercedes",
    timestamp: "2023-06-19",
    caption: "Beautiful Flower 🌷😊",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/354529060_258124666865649_3474662192993902914_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=wyC23lhioZMQ7kNvgElCoFt&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBfLm_5xxEOZYsmdFgytFTFO7-u-_pxSq4lPB13h2lt9g&oe=66AEB2CF",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/CtrSMoCKD_b/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17960712296188853",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-27",
    caption:
      "Welcome every morning with a smile. Look on the new day as another special gift from your Creator, another golden opportunity to complete what you were unable to finish yesterday.\n#sunset_madness \n#smile",
    media_url:
      "https://scontent-iad3-2.cdninstagram.com/v/t39.30808-6/332902351_997324761248103_5441942075064816994_n.png?stp=dst-jpg&_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=1x4ZsgVqC08Q7kNvgFhnNAo&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYDnb4T9OFzcvl_59ydrs6DWvr4gCa7fvwyXf8AgEM-Brw&oe=66AEA17F",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/CpKXdnqI9kW/",
    children: false,
    like_count: 0,
    comments_count: 1,
  },
  {
    id: "18023304841487735",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption:
      "The sea is a reminder of the vastness and mystery of the natural world. It reminds us to appreciate the beauty and power of nature.\n#mountain \n#sea \n#beach\n#nature",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.36329-15/332366878_880841606505927_7452305103494035857_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=uWZjzv2kT94Q7kNvgGFVHA9&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYDPxC-Oz85vyKAGP_rN0l-QNuReJbBUvoZ3s-PPauGopw&oe=66AEBA51",
    thumbnail_url: false,
    media_type: "CAROUSEL_ALBUM",
    permalink: "https://www.instagram.com/p/Co7WP7mKfP7/",
    children: {
      data: [
        {
          id: "17958799148203287",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/o1/v/t16/f2/m69/An8SXrQlsk47LWBOwY79hVfsB__L9U8yz85phisJORTbpE4GbEVFM5awoWIM66IieZibXxmrMXXin46cmfGw8gUx?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNhcm91c2VsX2l0ZW0udW5rbm93bi1DMy43MjAuZGFzaF9iYXNlbGluZV8xX3YxIn0&_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=104&vs=1316704599175642_985631799&_nc_vs=HBksFQIYOnBhc3N0aHJvdWdoX2V2ZXJzdG9yZS9HTzdvemhPQ2YzQ05zQmdDQUROXzVFckNkZmcxYmtZTEFBQUYVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dJUmMxQk54b1Q4UFctTUxBTEtUd0lmZWltTWhia1lMQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJoKCj62mx9RAFQIoAkMzLBdAF%2BVgQYk3TBgSZGFzaF9iYXNlbGluZV8xX3YxEQB17gcA&ccb=9-4&oh=00_AYA4bPe3u8kUCLScx1YYk7LsC-KnMAS3DIc12FV7wNKeng&oe=66AAB38E&_nc_sid=1d576d",
          media_type: "VIDEO",
          thumbnail_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.36329-15/332366878_880841606505927_7452305103494035857_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=uWZjzv2kT94Q7kNvgGFVHA9&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYDPxC-Oz85vyKAGP_rN0l-QNuReJbBUvoZ3s-PPauGopw&oe=66AEBA51",
        },
        {
          id: "17982516982819362",
          media_url:
            "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/331787531_208093915214673_3421519930834628083_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=18de74&_nc_ohc=2FslsONFUGMQ7kNvgGaMTkP&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBrsXsJg3XyoeyLlIuEcK4b62HtIpyLuXyX-kDbCOUbbA&oe=66AE9346",
          media_type: "IMAGE",
        },
      ],
    },
    like_count: 4,
    comments_count: 2,
  },
  {
    id: "18033744523448670",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption:
      "A river cuts through rock, not because of its power, but because of its persistence.\n#river \n#waterfall \n#trees",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/o1/v/t16/f1/m82/6047E2762E04B454D5D212610E769081_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzMuNzIwLmRhc2hfYmFzZWxpbmVfMV92MSJ9&_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=106&vs=608404207814175_1774518512&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC82MDQ3RTI3NjJFMDRCNDU0RDVEMjEyNjEwRTc2OTA4MV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dCQk55aE9NQ09fV1pHZ0RBQ3NvSVFmTmt1bHRicV9FQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJtrkuMH01%2Bk%2FFQIoAkMzLBdANNJul41P3xgSZGFzaF9iYXNlbGluZV8xX3YxEQB1AAA%3D&ccb=9-4&oh=00_AYBfHq_Sm4lfc0U5Y4Ct7SIvz2ITqAs7heGlah4J5PBy3A&oe=66AABBFD&_nc_sid=1d576d",
    thumbnail_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.36329-15/332184384_594216605509627_7434665208515645944_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=18de74&_nc_ohc=pEUq4mkfuDkQ7kNvgHpRYIu&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBVo5VssS2_yMd5o4R-LdvhK0FBS6C3qn-8fOarnSsnLA&oe=66AE9D16",
    media_type: "VIDEO",
    permalink: "https://www.instagram.com/reel/Co7V1EHoR6H/",
    children: false,
    like_count: 1,
    comments_count: 2,
  },
  {
    id: "18259328650133549",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption:
      "The sun rises every day, reminding us that each new day is a fresh start\n#life \n#beauty\n#peace",
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/332492141_748901040240328_2294855249781061265_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=J36X1tt9WJAQ7kNvgHvp6D_&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&gid=AvhPosUBpTZo3Z74UnsGeae&oh=00_AYBczl8ZLU7S6k0O7C92md1X1bJlqzKydeOVwg-cfPxtsQ&oe=66AEAA20",
    thumbnail_url: false,
    media_type: "CAROUSEL_ALBUM",
    permalink: "https://www.instagram.com/p/Co7TCbXqwmB/",
    children: {
      data: [
        {
          id: "18030225007457719",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/332492141_748901040240328_2294855249781061265_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=18de74&_nc_ohc=J36X1tt9WJAQ7kNvgHvp6D_&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&gid=AvhPosUBpTZo3Z74UnsGeae&oh=00_AYBczl8ZLU7S6k0O7C92md1X1bJlqzKydeOVwg-cfPxtsQ&oe=66AEAA20",
          media_type: "IMAGE",
        },
        {
          id: "18079098046334142",
          media_url:
            "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/332329786_753147869349186_1625085477250702849_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=SRBDuM_UMncQ7kNvgEDVP8S&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&gid=AvhPosUBpTZo3Z74UnsGeae&oh=00_AYC1KLqOq4KZoRYGFBrjqr_n0iE23zcLtwhi4IYohesPMQ&oe=66AEBD4D",
          media_type: "IMAGE",
        },
        {
          id: "18283176529099481",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/331870106_519230083462824_3834019571202263126_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=AsFAhja_JK8Q7kNvgG-8t85&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&gid=AvhPosUBpTZo3Z74UnsGeae&oh=00_AYAwMlwfVEwvVBmyRdLvVLx-F7ukZp7BpbWnILd7qu5KYw&oe=66AE99E4",
          media_type: "IMAGE",
        },
      ],
    },
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17955579188434684",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption: false,
    media_url:
      "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/332357582_936958767321067_4863591558522410391_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=x_DU3oKOV8kQ7kNvgHBEGiX&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBijLSaUvWEbWU_QV5eRGDK0mrx1WUEE20ATvpLG4buGw&oe=66AE9464",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/Co7S_VaqkJi/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17905845353723044",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption:
      "Roses come in many different colors, each with its own unique meaning. Similarly, people come in many different forms and we should celebrate and appreciate our differences.\n#roses \n#blue",
    media_url:
      "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/332140384_237339511963903_359137410640257593_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=wrq0QWZ--YYQ7kNvgHMNix4&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYBg6u9KFVFy5NhyQUUzLvuA0y5V7GWuQJgAzNPZmIkkOg&oe=66AEB0C6",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/Co7S9epqgHi/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17944662878427508",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption:
      "Just as sunsets mark the end of a day, they can also symbolize the end of a chapter in our lives. It's important to reflect on our experiences and learn from them.",
    media_url:
      "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/332154518_579513177553676_91126067434696472_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=18de74&_nc_ohc=sSTw7QYtr8EQ7kNvgHj6xRy&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYC-8kIa2-qgqbvHHKwcq1TRv_MBDnQbsAcCuAmcSWN0jw&oe=66AE9F23",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/Co7S5urKaj8/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "17862245771894002",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption: false,
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/332044105_511816237562108_2208290250742979046_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=BLDh-2G8xH0Q7kNvgHqdGHJ&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYB6TzVzEJ4tpUCgLZkwlfhh1eTpS48eTgwtzbD00_dOng&oe=66AEAFDD",
    thumbnail_url: false,
    media_type: "CAROUSEL_ALBUM",
    permalink: "https://www.instagram.com/p/Co7SfVzqeiG/",
    children: {
      data: [
        {
          id: "17987070823892369",
          media_url:
            "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/332044105_511816237562108_2208290250742979046_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=BLDh-2G8xH0Q7kNvgHqdGHJ&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYB6TzVzEJ4tpUCgLZkwlfhh1eTpS48eTgwtzbD00_dOng&oe=66AEAFDD",
          media_type: "IMAGE",
        },
        {
          id: "17991168307775798",
          media_url:
            "https://scontent-iad3-2.cdninstagram.com/v/t51.29350-15/332263862_158967939908297_2607979261105230922_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=qT29IkmqP4UQ7kNvgFx_LoP&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCfPDh3T-AwhwglAeCUNI7zSlIEXGu9nYWN-Pmazq5UZQ&oe=66AE976D",
          media_type: "IMAGE",
        },
      ],
    },
    like_count: 0,
    comments_count: 0,
  },
  {
    id: "18099011329308219",
    username: "fernandotoressmercedes",
    timestamp: "2023-02-21",
    caption: false,
    media_url:
      "https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/331970868_531048909013671_4697808110855361348_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=5Ht3L3LiwXwQ7kNvgEELXzK&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&oh=00_AYCJ5_YD7lzLfHBCrZU3o3m0xS5cmDIDbiG73CLg-mVkAg&oe=66AE98BC",
    thumbnail_url: false,
    media_type: "IMAGE",
    permalink: "https://www.instagram.com/p/Co7Sc1aqMfM/",
    children: false,
    like_count: 0,
    comments_count: 0,
  },
];

const testArrRes = helpers.arraySpliter(testArrays, dataStore.settings.rows);
console.log(testArrRes);
</script>

<style scoped>
.template-container {
  padding: 1rem 0;
}
</style>
