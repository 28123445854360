<template>
  <!-- Next component should appear on admin only -->
  <HighlightAdminPage v-if="!dataStore.storefrontMode" />
  <GridTemplates />
</template>

<script setup>
import HighlightAdminPage from "@/components/Admin/LayoutPages/HighlightAdminPage";
import GridTemplates from "@/components/StylingTemplates/GridTemplates.vue";
import appDataStore from "@/stores/appDataStore";

import { onMounted } from "vue";

const dataStore = appDataStore();
// get media data
onMounted(() => {
  let output;
  if (dataStore.storefrontMode) {
    output = dataStore.responsiveCheck();
    console.log(output);
  }
  if (output?.columns === 1) {
    return;
  }

  dataStore.getMediaData(
    `https://ipfeed-api.numinix.com/api/return-account-type-data?limit=${
      dataStore.settings.columns * dataStore.settings.rows - 3
    }`,
    "POST"
  );
});
</script>
