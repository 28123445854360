<template>
  <div class="connect">
    <h4 class="connect-title">Lexmodo Photos</h4>
    <div class="connect-content">
      <p>
        Connect to Lexmodo Photos in minutes. Our native integration allows you
        to connect your Lexmodo store. Effortlessly expand to instagram or
        facebook to grow your sales.
      </p>
      <button @click="connectUser('personal')" class="connect-btn">
        <img
          src="../../../assets/icons/instagram_connect_button.svg"
          alt="instagram"
        />
      </button>
      <p style="margin: -22px auto">or</p>
      <button
        @click="connectUser('business')"
        class="connect-btn connect-btn-facebook"
      >
        <div>
          <img
            src="../../../assets/icons/Facebook_Logo.png"
            alt="facebook"
            width="80"
          />
        </div>
        <p>Connect now</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import appDataStore from "@/stores/appDataStore";

const store = appDataStore();

const connectUser = (accountType) => {
  store.authenticateUser(accountType);
};
</script>

<style scoped lang="scss">
// user connecting styling
.connect {
  text-align: center;
  .connect-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 19px;
  }
  .connect-content {
    padding: 31px 45px 45px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    width: 565px;
    min-height: 230px;
    background: #ffffff;
    border: 1px solid #edeeef;
    box-shadow: 0px 10px 13px -2px rgba(106, 115, 121, 0.05);
    border-radius: 12px;
  }
  .connect-btn {
    cursor: pointer;
    border: 0;
    outline: none;
    width: 295px;
    height: 52px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
  }
  .connect-btn-facebook {
    display: flex;
    gap: 50px;
    align-items: center;
    background: linear-gradient(to right, #3b5998, #3b5998);
    div {
      padding: 8px;
      border-right: 2.5px solid white;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    p {
      color: white;
      font-weight: 600;
      line-height: 14px;
    }
  }
}
</style>
