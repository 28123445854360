<template>
  <SubHeader>
    <template #title>Scroll</template>
    <template #description>
      Display posts from any public Highlight with an Instagram Highlight feed.
      Great for pulling in user-generated content associated with your brand,
      running promotional Highlight campaigns, engaging audiences at events, and
      so much more. Use the easy built-in options to completely customize the
      feed to drive engagement and produce results.
    </template>
  </SubHeader>
</template>

<script setup>
import SubHeader from "@/components/UI/SubHeader.vue";
import appDataStore from "@/stores/appDataStore";
import { onUnmounted, onMounted } from "vue";

const dataStore = appDataStore();
onUnmounted(() => {
  dataStore.setSettings({ columns: 4, rows: 2 });
});

onMounted(() => {
  dataStore.setSettings({ columns: 3, rows: 4 });
});
</script>
