<template>
  <!-- Next component should appear on admin only -->
  <ScrollAdminPage v-if="!dataStore.storefrontMode" />
  <ScrollTemplate />
</template>

<script setup>
import ScrollAdminPage from "@/components/Admin/LayoutPages/ScrollAdminPage";
// import GridTemplates from "@/components/StylingTemplates/GridTemplates.vue";
import ScrollTemplate from "@/components/StylingTemplates/ScrollTemplate.vue";
import appDataStore from "@/stores/appDataStore";
import { onMounted } from "vue";

const dataStore = appDataStore();

// get media data
onMounted(() => {
  let output;
  if (dataStore.storefrontMode) {
    output = dataStore.responsiveCheck();
    console.log(output);
  }
  let imagesCount = 7;
  if (output?.columns === 2) {
    imagesCount = 5;
  }
  // if (output?.columns === 1) {
  //   return;
  // }
  dataStore.getScrollMediaData(
    `https://ipfeed-api.numinix.com/api/return-account-type-data?limit=${imagesCount}`,
    "POST"
  );
});
</script>
