<template>
  <div
    class="pt-3 pb-1 w-full d-flex justify-content-center align-items-center min-h-"
    @scroll="handleScroll"
  >
    <p ref="observerElement" class="text-capitalize fs-5">
      <slot name="intersector_content">
        <template v-if="dataStore.subsequentLoading">
          <ScrollLoader />
        </template>
      </slot>
    </p>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from "vue";
// import ElSpinner from "@/components/ui/ElSpinner.vue";
// import ScrollLoader from "@/components/UI/ScrollLoader.vue";
import appDataStore from "@/stores/appDataStore";

const observerElement = ref(null);
const isLoading = ref(false);
const dataStore = appDataStore();

const props = defineProps({
  intersectionCallback: {
    type: Function,
    default: null,
  },
  callbackDelyMs: {
    default: 1000,
    type: Number,
  },
});

// Default callback if not provided
const defaultCallback = () => {
  if (dataStore.nextUrl) {
    dataStore.getScrollMediaData(dataStore.nextUrl, "GET");
  }
};

const intersectionCallback = props.intersectionCallback || defaultCallback;

const useIntersectionObserver = (callback) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLoading.value) {
          callback();
        }
      });
    },
    {
      root: null,
      threshold: 0.1,
    }
  );

  return observer;
};

const loadMoreItems = () => {
  isLoading.value = true;
  // Simulating API call
  setTimeout(() => {
    // Load more items logic here
    intersectionCallback();
    isLoading.value = false;
  }, props.callbackDelyMs);
};

const observer = useIntersectionObserver(loadMoreItems);

onMounted(() => {
  if (observerElement.value) {
    observer.observe(observerElement.value);
  }
});

onUnmounted(() => {
  if (observerElement.value) {
    observer.unobserve(observerElement.value);
  }
});

const handleScroll = () => {
  // Additional scroll handling if needed
};
</script>
