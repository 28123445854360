<template>
  <CarouselAdminPage v-if="!dataStore.storefrontMode" />
  <CarouselTemplate />
</template>

<script setup>
import CarouselAdminPage from "@/components/Admin/LayoutPages/CarouselAdminPage";
import CarouselTemplate from "@/components/StylingTemplates/CarouselTemplate.vue";

import { onMounted } from "vue";
import appDataStore from "@/stores/appDataStore";

const dataStore = appDataStore();

// get media data
onMounted(() => {
  if (dataStore.storefrontMode) {
    let output = dataStore.responsiveCheck();
    console.log(output);
  }
  dataStore.getMediaData(
    `https://ipfeed-api.numinix.com/api/return-account-type-data`,
    "POST"
  );
});
</script>
