<template>
  <SubHeader>
    <template #title>Carousel</template>
    <template #description>
      Carousel feeds are perfect for when you either want to display a lot of
      content in a small space or want to catch your visitors attention. Create
      beautiful photo or video carousels of your Instagram content. Create
      multiple carousels on the same page or throughout your site, with 1 or 2
      rows and infinite scrolling.
    </template>
  </SubHeader>
  <div class="setting">
    <div>
      <label for="columns">Number of Items per carousel:</label>
      <prime-dropdown
        :loading="dataStore.mediaArrayLoading"
        inputId="columns"
        v-model="dataStore.settings.columns"
        :options="columnsOptions"
        optionLabel="value"
        optionValue="value"
        placeholder="Select number of Items per carousel"
        class="w-full md:w-14rem"
      />
    </div>
    <div>
      <label for="rows">Number Carousels:</label>
      <prime-dropdown
        :loading="dataStore.mediaArrayLoading"
        inputId="rows"
        v-model="dataStore.settings.rows"
        :options="rowsOptions"
        optionLabel="value"
        optionValue="value"
        placeholder="Select number of carousels"
        class="w-full md:w-14rem"
      />
    </div>
  </div>
</template>

<script setup>
import SubHeader from "@/components/UI/SubHeader.vue";
import { onUnmounted, ref, watch } from "vue";
import appDataStore from "@/stores/appDataStore";

const dataStore = appDataStore();
const rowsOptions = ref([
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
]);
const columnsOptions = ref([{ value: 2 }, { value: 3 }, { value: 4 }]);

// reset settings
onUnmounted(() => {
  dataStore.setSettings({ columns: 4, rows: 2 });
});
// watch if user changed layout settings and hence save setting in server.
watch(
  () => [dataStore.settings.columns, dataStore.settings.rows],
  (newValue) => {
    const [newColumnsValue, newRowsValue] = newValue;

    dataStore.saveSettingsInServer({
      length: newColumnsValue,
      width: newRowsValue,
    });
  }
);
</script>
<style scoped lang="scss">
.setting {
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
</style>
