import axios from "axios";

export default {
  /**
   * @param {Array} arr "Array to be divided"
   * @param {Number} numOfArrays  "Number of Arrays that the inputed array should be splitted to"
   * @returns {Array} "Return Array of divided arrays"
   */
  arraySpliter(arr = [], numOfArrays = 1) {
    const arrayOfArrays = [];
    const newArrayLength = Math.ceil(arr.length / numOfArrays);
    for (let i = 0; i < numOfArrays; i++) {
      const splittedArray = arr.slice(
        newArrayLength * i,
        newArrayLength * i + newArrayLength
      );
      // if the sliced array length is less than newArray length fill it with null values
      if (splittedArray.length !== newArrayLength) {
        let lengthDifference = newArrayLength - splittedArray.length;
        for (let j = 0; j < lengthDifference; j++) {
          splittedArray.push(null);
        }
      }
      arrayOfArrays.push(splittedArray);
    }
    return arrayOfArrays;
  },
  captilaizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.substring(1, string.length);
  },
  /**
   * @param {string} dateString 	"YYYY-MM-DD"
   */
  formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${monthName.toUpperCase()} ${day}, ${year}`;
  },
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_instagram_feed")}`,
    };
  },
  getHeaderNoAuth() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  },
  /**
   * @param {string} endPoint 	https://ipfeed.numinix.com/api/{endPoint}
   * @param {string} method examples: "get" , "post".
   * @param {object} params request parameters object.
   */
  async sendRequest(endPoint, method, params = {}, storeId) {
    // url: if storeId has value edit url and headers
    let url = `https://ipfeed-api.numinix.com/api/${endPoint}`;
    if (storeId) {
      let addedQueryString = `?store_id=${storeId}`;
      url += addedQueryString;
    }
    let requestHeader = storeId ? this.getHeaderNoAuth() : this.getHeader();

    // GET METHOD
    if (method === "get") {
      // GET METHOD WITH EMPTY PARAMS
      if (Object.keys(params).length === 0) {
        return axios
          .get(url, {
            headers: requestHeader,
          })
          .then((res) => res.data);
      }
      // GET METHOD WITH PARAMS
      return axios
        .get(url, {
          params: {
            dt_params: JSON.stringify({ ...params }),
          },
          headers: requestHeader,
        })
        .then((res) => res.data);
    }
    // Post Method
    if (method === "post") {
      return axios
        .post(url, params, {
          headers: requestHeader,
        })
        .then((res) => res.data);
    }
    if (!endPoint || !method) {
      return new Error(
        "End Point or method is missing from sendRequest function"
      );
    }
  },
  // async sendRequestNoAuth(endPoint, method, params = {}, storeId) {
  //   // GET METHOD
  //   let url = `https://ipfeed-api.numinix.com/api/${endPoint}?store_id=${storeId}`;
  //   if (method === "get") {
  //     // GET METHOD WITH EMPTY PARAMS
  //     if (params === {}) {
  //       return axios
  //         .get(url, {
  //           headers: this.getHeaderNoAuth(),
  //         })
  //         .then((res) => res.data);
  //     }
  //     // GET METHOD WITH PARAMS
  //     return axios
  //       .get(url, {
  //         params: {
  //           dt_params: JSON.stringify({ ...params }),
  //         },
  //         headers: this.getHeaderNoAuth(),
  //       })
  //       .then((res) => res.data);
  //   }
  //   // Post Method
  //   if (method === "post") {
  //     return axios
  //       .post(url, params, {
  //         headers: this.getHeaderNoAuth(),
  //       })
  //       .then((res) => res.data);
  //   }
  //   if (!endPoint || !method) {
  //     return new Error(
  //       "End Point or method is missing from sendRequest function"
  //     );
  //   }
  // },
};
