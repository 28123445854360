import { createRouter, createWebHistory } from "vue-router";

import App from "@/App.vue";
const routes = [
  { path: "/", redirect: "/instagram_feed" },
  { path: "/instagram_feed", component: App },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active-tab",
});

export default router;
