<template>
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.62109 7.5L5.22407 6.32082L1.1129 2.20431C0.654166 1.74493 0.652409 0.998377 1.10897 0.536815C1.56553 0.0751931 2.30757 0.0734838 2.7663 0.532806L8.88959 6.66418L8.89064 6.6653C9.3482 7.12468 9.34967 7.87365 8.89076 8.33457L8.8897 8.33568L2.76642 14.4671C2.30774 14.9263 1.56571 14.9247 1.10909 14.463C0.652526 14.0015 0.654284 13.2549 1.11301 12.7956L5.76758 8.5L6.62109 7.5Z"
      fill="#575959"
    />
  </svg>
</template>
