<template>
  <SubHeader>
    import GridTemplates from "@/components/StylingTemplates/GridTemplates.vue";
    <template #title>Highlight</template>
    <template #description>
      Display posts from any public Highlight with an Instagram Highlight feed.
      Great for pulling in user-generated content associated with your brand,
      running promotional Highlight campaigns, engaging audiences at events, and
      so much more. Use the easy built-in options to completely customize the
      feed to drive engagement and produce results.
    </template>
  </SubHeader>
  <div class="setting">
    <div>
      <label for="columns">Number of columns:</label>
      <prime-dropdown
        :loading="dataStore.mediaArrayLoading"
        inputId="columns"
        v-model="dataStore.settings.columns"
        :options="columnsOptions"
        optionLabel="value"
        optionValue="value"
        placeholder="Select number of columns"
        class="w-full md:w-14rem"
      />
    </div>
    <div>
      <label for="rows">Number of rows:</label>
      <prime-dropdown
        :loading="dataStore.mediaArrayLoading"
        inputId="rows"
        v-model="dataStore.settings.rows"
        :options="rowsOptions"
        optionLabel="value"
        optionValue="value"
        placeholder="Select number of rows"
        class="w-full md:w-14rem"
      />
    </div>
  </div>
</template>

<script setup>
import SubHeader from "@/components/UI/SubHeader.vue";
import appDataStore from "@/stores/appDataStore";
import { onUnmounted, ref, watch } from "vue";

const dataStore = appDataStore();
const rowsOptions = ref([{ value: 2 }, { value: 3 }, { value: 4 }]);
const columnsOptions = ref([{ value: 3 }, { value: 4 }, { value: 5 }]);

// reset settings
onUnmounted(() => {
  dataStore.setSettings({ columns: 4, rows: 2 });
});

// watch if user changed layout settings and hence save setting in server.
// and also get the new data based on settings.
watch(
  () => [dataStore.settings.columns, dataStore.settings.rows],
  (newValue) => {
    const [newColumnsValue, newRowsValue] = newValue;

    dataStore.saveSettingsInServer({
      length: newColumnsValue,
      width: newRowsValue,
    });
    dataStore.getMediaData(
      `https://ipfeed-api.numinix.com/api/return-account-type-data?limit=${
        newRowsValue * newColumnsValue - 3
      }`,
      "POST"
    );
  }
);
</script>

<style scoped lang="scss">
.setting {
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
</style>
