// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageCard_img-container_sCXmv[data-v-178f4f28]{position:relative;min-height:30vh;width:100%;height:100%;margin-right:-1.25rem}.ImageCard_img-container_sCXmv img[data-v-178f4f28],.ImageCard_img-container_sCXmv video[data-v-178f4f28]{-o-object-fit:cover;object-fit:cover;width:100%;height:100%}.ImageCard_img-overlay_LLFdM[data-v-178f4f28]{cursor:pointer;opacity:0;display:flex;color:#fff;padding:0 .8rem;position:absolute;flex-direction:column;top:0;bottom:0;right:0;left:0;justify-content:center;align-items:center;text-align:center;gap:.8rem;background:rgba(0,0,0,.5);transition:opacity .5s}.ImageCard_img-overlay_LLFdM p.ImageCard_img-caption_WcDHk[data-v-178f4f28],.ImageCard_img-overlay_LLFdM p.ImageCard_img-date_W1AMy[data-v-178f4f28]{color:#fff;font-size:14px}.ImageCard_img-caption_WcDHk[data-v-178f4f28]{display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;font-size:14px}.ImageCard_img-container_sCXmv:hover .ImageCard_img-overlay_LLFdM[data-v-178f4f28]{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img-container": "ImageCard_img-container_sCXmv",
	"img-overlay": "ImageCard_img-overlay_LLFdM",
	"img-caption": "ImageCard_img-caption_WcDHk",
	"img-date": "ImageCard_img-date_W1AMy"
};
export default ___CSS_LOADER_EXPORT___;
