// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GridTemplates_template-container_pF0Nv[data-v-67a5145e]{padding:1.25rem;min-height:20vh;display:grid;grid-template-columns:repeat(3,1fr);-moz-column-gap:1.5rem;column-gap:1.5rem;row-gap:1.5rem;position:relative}.GridTemplates_template-container_pF0Nv button.GridTemplates_paging-btn_KKM__[data-v-67a5145e]{cursor:pointer;padding:0;position:absolute;top:50%;transform:translateY(-50%);z-index:1;border:1px solid #ccc;background:#eee;border-radius:100px;width:45px;height:45px;display:flex;justify-content:center;align-items:center}.GridTemplates_template-container_pF0Nv button.GridTemplates_paging-btn_KKM__.GridTemplates_left_veYaO[data-v-67a5145e]{left:15px}.GridTemplates_template-container_pF0Nv button.GridTemplates_paging-btn_KKM__.GridTemplates_right_j96EG[data-v-67a5145e]{right:15px}.GridTemplates_template-container_pF0Nv button.GridTemplates_paging-btn_KKM__[data-v-67a5145e]:hover{background-color:#bbb}.GridTemplates_template-container_pF0Nv button.GridTemplates_paging-btn_KKM__[data-v-67a5145e]:active{background-color:#aaa}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template-container": "GridTemplates_template-container_pF0Nv",
	"paging-btn": "GridTemplates_paging-btn_KKM__",
	"left": "GridTemplates_left_veYaO",
	"right": "GridTemplates_right_j96EG"
};
export default ___CSS_LOADER_EXPORT___;
