<template>
  <div v-if="statusApp">
    <UpperBar title="Lexmodo Photos">
      <PluginLogoSvg></PluginLogoSvg>
    </UpperBar>
    <!-- If user is not logged in with facebook or instagram navigate to the connect page -->
    <ConnectPage
      v-if="!store.isUserAuthenticated && !store.loadingConnectCheck"
    />
    <!-- Else navigate to the app page -->
    <template
      v-else-if="store.isUserAuthenticated && !store.loadingConnectCheck"
    >
      <MainHeader />
      <NavBar @set-tab="setTab" :currentComponent="currentTab" />
      <div
        class="menu-tab"
        style="border-top-left-radius: 0; border-top-right-radius: 0"
      >
        <component :is="currentTab"></component>
      </div>
    </template>
    <LoadingPage v-else />
  </div>
  <LoadingPage v-else />
</template>

<script setup>
//components imports
import UpperBar from "./components/UpperBar.vue";
import MainHeader from "./components/MainHeader.vue";
import NavBar from "./components/NavBar.vue";
import PluginLogoSvg from "./components/Icons/PluginLogoSvg.vue";
import ConnectPage from "./components/Admin/ConnectPage/ConnectPage";
import LoadingPage from "./components/UI/LoadingPage.vue";

import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { authLogin } from "./services/authLogin";
import appDataStore from "./stores/appDataStore";
import helpers from "./services/helpers";

const store = appDataStore();
const currentTab = ref("");

watch(
  () => store.selectedTemplate,
  () => {
    currentTab.value =
      helpers.captilaizeFirstLetter(store.selectedTemplate) + "Page";
  }
);
//declare functions
const setTab = (componentData) => {
  store.setTemplate(componentData.tabLabel);
};

// authentication
const statusApp = ref(false),
  auth = new authLogin(),
  router = useRouter(),
  widgetData = ref({}),
  productWidget = ref(false);

auth
  .startApp()
  ?.then((data) => {
    // console.log("auth_token: " + data.meta.token);
    if (!data.meta.redirect) {
      if (data.product_widget?.product_widget === true) {
        widgetData.value = data;
        statusApp.value = true;
        productWidget.value = true;
        router.push("/widgets");
      } else {
        statusApp.value = true;
        router.push("/instagram_feed");
      }
    }
  })
  .then(() => {
    store.checkUserType();
  });
</script>
