<template>
  <!-- set a value for the storefrontMode in pinia store -->
  <AppAdmin v-if="!store.storefrontMode" />
  <AppStorefront v-else />
</template>

<script setup>
//components imports
import AppAdmin from "./AppAdmin.vue";
import AppStorefront from "./AppStorefront.vue";

//store import
import appDataStore from "./stores/appDataStore";

// intialize store
const store = appDataStore();
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
}

#app-lexmodo-photos {
  padding: 44px;
  min-height: 100vh;
  font-style: normal;
  background-color: #f2f5fa;
  scrollbar-gutter: "stable";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.menu-tab {
  background: #ffffff;
  border: 1px solid #edeeef;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;

  position: relative;
}

.app-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev {
  background-color: white !important;
  color: #000 !important;
}
/*
Next styles are placed globaly to affect images with dyanmic classes inside GridTemplates component,
as using css modules confilict using dyanmic classes
*/
.first-img {
  grid-area: 1 / 1 / 3 / 3;
}
.first-img-custom {
  grid-area: 1 / 1 / 2 / 3;
  aspect-ratio: 2/1;
}
.second-img-custom {
  grid-area: 1 / 3 / 3 / 4;
}
.third-img-custom {
  grid-area: 2 / 1 / 4 / 2;
}
.fourth-img-custom {
  grid-area: 2 / 2 / 4 / 3;
}
.fifth-img-custom {
  grid-area: 3 / 3 / 5 / 4;
}
.sixth-img-custom {
  grid-area: 4 / 1 / 5 / 2;
}
.seventh-img-custom {
  grid-area: 4 / 2 / 5 / 3;
}

//Media queries will handle the distances without fonts
@media only screen and (max-width: 1000px) {
  :root {
    font-size: 14px;
  }
}
@media only screen and (max-width: 950px) {
  :root {
    font-size: 12px;
  }

  .second-img-custom,
  .third-img-custom,
  .fourth-img-custom,
  .fifth-img-custom,
  .sixth-img-custom,
  .seventh-img-custom {
    grid-area: auto;
  }
}
@media only screen and (max-width: 800px) {
  :root {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    font-size: 10px;
  }
}
@media only screen and (max-width: 400px) {
  :root {
    font-size: 8px;
  }
}
</style>
