<template>
  <div
    :class="$style['template-container']"
    :style="containerGridSize"
    v-if="!dataStore.initialLoading && dataStore.mediaArray.length > 0"
  >
    <ImageCard
      v-for="(media, index) in dataStore.mediaArray"
      :key="media.id"
      :mediaData="media"
      @show-modal="showModal"
      :class="imageClasses(index)"
    />
    <ModalOverlay v-if="modalVisibility" :hideModalFn="hideModal">
      <MediaDetails :mediaData="curentModalData" :hideModalFn="hideModal" />
    </ModalOverlay>

    <ScrollObserver />
  </div>

  <div
    v-else-if="dataStore.initialLoading"
    style="
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      justify-content: center;
      align-items: center;
    "
  >
    <Skeleton
      width="95%"
      :height="dataStore.minimumImageHeight"
      v-for="num in dataStore.settings.rows"
      :key="num"
    ></Skeleton>
  </div>
  <div
    v-else
    style="
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <h3>Media Data is empty</h3>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import appDataStore from "@/stores/appDataStore";

import MediaDetails from "../MediaDetails.vue";
import ModalOverlay from "../UI/ModalOverlay.vue";
import Skeleton from "primevue/skeleton";
// import LeftArrowSvg from "../UI/IconsSvg/LeftArrowSvg.vue";
// import RightArrowSvg from "../UI/IconsSvg/RightArrowSvg.vue";
import ScrollObserver from "../ScrollObserver.vue";

onMounted(() => {
  dataStore.windowWidth = +getComputedStyle(
    document.querySelector("#app-lexmodo-photos")
  ).width.replace("px", "");
});

const dataStore = appDataStore(),
  // modal states
  curentModalData = ref(null),
  modalVisibility = ref(false);

console.log("Media data", dataStore.mediaArray);

//Computed properties
const containerGridSize = computed(() => {
  if (
    dataStore.selectedTemplate === "custom" ||
    dataStore.selectedTemplate === "scroll"
  ) {
    return {
      "grid-template-columns": `repeat(${dataStore.settings.columns}, 1fr)`,
    };
  }
  return {
    "grid-template-columns": `repeat(${dataStore.settings.columns}, 1fr)`,
    "grid-template-rows": `repeat(${dataStore.settings.rows}, 1fr)`,
  };
});

//Methods
const showModal = (payload) => {
  curentModalData.value = payload;
  modalVisibility.value = true;
};
const hideModal = () => {
  curentModalData.value = null;
  modalVisibility.value = false;
};
const imageClasses = (index) => {
  const classes = {
    "first-img": index === 0 && dataStore.selectedTemplate === "highlight",
    "first-img-custom": index === 0 && dataStore.selectedTemplate === "custom",
    "second-img-custom": index === 1 && dataStore.selectedTemplate === "custom",
    "third-img-custom": index === 2 && dataStore.selectedTemplate === "custom",
    "fourth-img-custom": index === 3 && dataStore.selectedTemplate === "custom",
    "fifth-img-custom": index === 4 && dataStore.selectedTemplate === "custom",
    "sixth-img-custom": index === 5 && dataStore.selectedTemplate === "custom",
    "seventh-img-custom":
      index === 6 && dataStore.selectedTemplate === "custom",
  };
  return classes;
};
</script>

<style scoped lang="scss" module>
.template-container {
  padding: 1.25rem;
  min-height: 20vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  position: relative;

  button.paging-btn {
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border: 1px solid #ccc;
    background: #eee;
    border-radius: 100px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button.paging-btn.left {
    left: 15px;
  }
  button.paging-btn.right {
    right: 15px;
  }
  button.paging-btn:hover {
    background-color: #bbb;
  }

  button.paging-btn:active {
    background-color: #aaa;
  }
}
</style>
