import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import ConfirmationService from "primevue/confirmationservice";

// import dotenv from "dotenv";
// dotenv.config();

// Global components
import StandardPage from "./views/StandardPage.vue";
import HighlightPage from "./views/HighlightPage.vue";
import CustomPage from "./views/CustomPage.vue";
import CarouselPage from "./views/CarouselPage.vue";
import ScrollPage from "./views/ScrollPage.vue";
import ImageCard from "./components/UI/ImageCard";

// Primevue, Bootstrap and other components
import PrimeVue from "primevue/config";
import PrimeDropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";

//P rimevue css
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

const app = createApp(App);
const pinia = createPinia();

app.component("PrimeDropdown", PrimeDropdown);
app.component("StandardPage", StandardPage);
app.component("CarouselPage", CarouselPage);
app.component("CustomPage", CustomPage);
app.component("ScrollPage", ScrollPage);
app.component("HighlightPage", HighlightPage);
app.component("ImageCard", ImageCard);

app.use(PrimeVue);
app.use(pinia);
app.use(router);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);

app.mount("#app-lexmodo-photos");
