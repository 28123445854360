<template>
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.37891 7.5L4.77593 6.32082L8.8871 2.20431C9.34583 1.74493 9.34759 0.998377 8.89103 0.536815C8.43447 0.0751931 7.69243 0.0734838 7.2337 0.532806L1.11041 6.66418L1.10936 6.6653C0.651799 7.12468 0.650335 7.87365 1.10924 8.33457L1.1103 8.33568L7.23358 14.4671C7.69226 14.9263 8.43429 14.9247 8.89091 14.463C9.34747 14.0015 9.34572 13.2549 8.88699 12.7956L4.23242 8.5L3.37891 7.5Z"
      fill="#575959"
    />
  </svg>
</template>
