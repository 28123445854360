<template>
  <link rel="stylesheet" href="https://unpkg.com/primeicons/primeicons.css" />
  <link
    rel="stylesheet"
    href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css"
  />
  <link
    rel="stylesheet"
    href="https://unpkg.com/primevue/resources/primevue.min.css"
  />
  <template v-if="store.isUserAuthenticated && !store.loadingConnectCheck">
    <div class="menu-tab">
      <component :is="currentTab"></component>
    </div>
  </template>
  <LoadingPage v-if="!store.isUserAuthenticated && store.loadingConnectCheck" />
  <p
    v-if="!store.isUserAuthenticated && !store.loadingConnectCheck"
    class="admin-message"
  >
    Admin Message: Please Install Instagram plugin and complete authentication.
  </p>
</template>

<script setup>
console.log("AppStorefront");
//components imports
import LoadingPage from "./components/UI/LoadingPage.vue";

import { onMounted, onUnmounted, ref, watch } from "vue";
import appDataStore from "./stores/appDataStore";
import helpers from "./services/helpers";

const store = appDataStore();
const currentTab = ref("");
const windowResizeTimer = ref(null);

const handleWindowResize = () => {
  if (windowResizeTimer.value !== null) {
    window.clearTimeout(windowResizeTimer.value);
    windowResizeTimer.value = null;
  } else {
    windowResizeTimer.value = window.setTimeout(() => {
      store.responsiveCheck();
    }, 50);
  }
};

// get the user data
onMounted(() => {
  store.getStoreId();
  store.checkUserType();
  // override admin styles
  document.getElementById("app-lexmodo-photos").style.backgroundColor =
    "transparent";
  document.getElementById("app-lexmodo-photos").style.padding = "20px";
  document.getElementById("app-lexmodo-photos").style.minHeight = "auto";
  document.getElementById("app-lexmodo-photos").style.color = "#202223";

  // width change eventListener
  window.addEventListener("resize", handleWindowResize);
});
onUnmounted(() => {
  window.removeEventListener("resize", handleWindowResize);
});
// when selected template value is fetched set current tab
watch(
  () => store.selectedTemplate,
  () => {
    currentTab.value =
      helpers.captilaizeFirstLetter(store.selectedTemplate) + "Page";
  }
);
</script>
