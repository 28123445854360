// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScrollTemplate_template-container_nfUWq[data-v-7d8cc660]{padding:1.25rem;min-height:20vh;display:grid;grid-template-columns:repeat(3,1fr);-moz-column-gap:1.5rem;column-gap:1.5rem;row-gap:1.5rem;position:relative}.ScrollTemplate_template-container_nfUWq button.ScrollTemplate_paging-btn__ylul[data-v-7d8cc660]{cursor:pointer;padding:0;position:absolute;top:50%;transform:translateY(-50%);z-index:1;border:1px solid #ccc;background:#eee;border-radius:100px;width:45px;height:45px;display:flex;justify-content:center;align-items:center}.ScrollTemplate_template-container_nfUWq button.ScrollTemplate_paging-btn__ylul.ScrollTemplate_left_pXe0n[data-v-7d8cc660]{left:15px}.ScrollTemplate_template-container_nfUWq button.ScrollTemplate_paging-btn__ylul.ScrollTemplate_right_riZlj[data-v-7d8cc660]{right:15px}.ScrollTemplate_template-container_nfUWq button.ScrollTemplate_paging-btn__ylul[data-v-7d8cc660]:hover{background-color:#bbb}.ScrollTemplate_template-container_nfUWq button.ScrollTemplate_paging-btn__ylul[data-v-7d8cc660]:active{background-color:#aaa}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template-container": "ScrollTemplate_template-container_nfUWq",
	"paging-btn": "ScrollTemplate_paging-btn__ylul",
	"left": "ScrollTemplate_left_pXe0n",
	"right": "ScrollTemplate_right_riZlj"
};
export default ___CSS_LOADER_EXPORT___;
