<template>
  <div :class="$style['img-container']" v-if="mediaData">
    <template v-if="!loading">
      <img
        :alt="mediaData.media_type"
        :src="mediaData.thumbnail_url || mediaData.media_url"
        style="border: 2px solid #bbb"
      />
    </template>
    <Skeleton
      v-else
      width="99%"
      height="100%"
      :style="{ minWidth: store.minimumImageHeight || '200px' }"
    ></Skeleton>
    <div :class="$style['img-overlay']" @click="showModal">
      <p :class="$style['img-caption']">
        <template v-if="mediaData.caption">
          {{ mediaData.caption }}
        </template>
        <template v-else>No Caption</template>
      </p>
      <p :class="$style['img-date']">
        {{ helpers.formatDate(mediaData.timestamp) }}
      </p>
    </div>
  </div>
  <div :class="$style['img-container']" v-else>
    <img
      src="../../assets/images/ImageThumbnail.png"
      style="border: 2px solid #bbb"
    />
  </div>
</template>

<script setup>
import Skeleton from "primevue/skeleton";
import { defineProps, defineEmits, onMounted, ref } from "vue";
import helpers from "@/services/helpers";
import appDataStore from "@/stores/appDataStore";

const store = appDataStore();
const loading = ref(true);
const props = defineProps(["mediaData"]);
const emit = defineEmits(["showModal"]);

// fetch the media src and check if it is loaded
const isMediaLoaded = () => {
  const mediaType = props.mediaData.media_type;
  const media = document.createElement(mediaType === "VIDEO" ? "video" : "img");
  const callBack = () => (loading.value = false);

  media.src = props.mediaData.media_url;
  if (mediaType === "VIDEO") {
    media.onloadeddata = callBack;
  } else {
    media.onload = callBack;
  }
};
const showModal = () => {
  emit("showModal", props.mediaData);
};
onMounted(() => {
  props.mediaData && isMediaLoaded();
});
</script>

<style scoped lang="scss" module>
.img-container {
  position: relative;
  min-height: 30vh;
  width: 100%;
  height: 100%;
  // aspect-ratio: 1 / 1;
  margin-right: -1.25rem; /* remove added distance by the aspect ratio */
}
.img-container img,
.img-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-overlay {
  cursor: pointer;
  opacity: 0;
  display: flex;
  color: white;
  padding: 0 0.8rem;
  position: absolute;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.8rem;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
  p.img-caption,
  p.img-date {
    color: white;
    font-size: 14px;
  }
}
.img-caption {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.img-container:hover .img-overlay {
  opacity: 1;
}
</style>
