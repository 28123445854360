<template>
  <nav>
    <ul>
      <li
        v-for="item in navData"
        :key="item.tabLabel"
        @click="setCurrentTab(item)"
        :class="{ 'active-tab': props.currentComponent === item.componentName }"
      >
        <span>{{ item.tabLabel }}</span>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import appDataStore from "@/stores/appDataStore";
import { ref, defineEmits, defineProps } from "vue";

const emit = defineEmits(["setTab"]);
const props = defineProps(["currentComponent"]);
const store = appDataStore();

const setCurrentTab = (componentData) => {
  if (store.mediaArrayLoading) {
    return;
  }
  emit("setTab", componentData);
};
const navData = ref([
  {
    tabLabel: "standard",
    componentName: "StandardPage",
  },
  {
    tabLabel: "highlight",
    componentName: "HighlightPage",
  },
  {
    tabLabel: "custom",
    componentName: "CustomPage",
  },
  {
    tabLabel: "carousel",
    componentName: "CarouselPage",
  },
  {
    tabLabel: "scroll",
    componentName: "ScrollPage",
  },
]);
</script>

<style scoped>
nav {
  padding: 0 10px;
  width: 100%;
  height: 44px;
  background-color: #fafbfb;
}

ul {
  height: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 53px;
}

ul li {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul li:hover {
  border-bottom: 3px solid #cecece;
}
ul li.active-tab:hover {
  border-bottom: 3px solid #00206f;
}

.active-tab {
  border-bottom: 3px solid #00206f;
}

li span {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #6d6d6d;
  text-transform: capitalize;
  height: 100%;
}

@media only screen and (max-width: 530px) {
  nav {
    height: auto;
    padding: 20px;
  }
  ul {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    gap: 20px;
    border-bottom: none;
    flex-direction: column;
  }
}
</style>
