<template>
  <header class="main-header">
    <h4>{{ store.userAuthType }} Feeder</h4>
    <div class="actions">
      <!-- <button
        @click="
          confirmUserAction(
            'Are you sure?, You will be logged out!',
            store.switchAccountType
          )
        "
      >
        Switch account
      </button> -->
      <button
        @click="
          confirmUserAction(
            'Are you sure?, All your data will be lost!',
            store.removeUserData
          )
        "
        class="delete"
      >
        Remove account
      </button>
    </div>
  </header>
  <ConfirmDialog></ConfirmDialog>
</template>

<script setup>
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import appDataStore from "@/stores/appDataStore";

const confirm = useConfirm();
const store = appDataStore();

// const confirmDataRemove = () => {
//   confirm.require({
//     message: "Are you sure?, All your data will be lost!",
//     header: "Confirmation",
//     icon: "pi pi-exclamation-triangle",
//     accept: () => store.removeUserData(),
//   });
// };
const confirmUserAction = (message, acceptFunction) => {
  confirm.require({
    message,
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: acceptFunction,
  });
};
</script>

<style scoped lang="scss">
header.main-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 91px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: radial-gradient(
    126.96% 126.96% at 6.47% 97.81%,
    #ec44d2 6.77%,
    #1a92ee 100%
  );
  color: #ffffff;

  h4 {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  button {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    background-color: transparent;
    color: inherit;
    border: none;
    &.delete {
      color: rgb(255, 215, 215);
      &:hover {
        color: rgb(255, 150, 150);
      }
    }
  }

  .actions {
    right: 2rem;
    position: absolute;
    // text-decoration-line: underline;

    display: flex;
    gap: 15px;
  }
}
</style>
