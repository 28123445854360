<template>
  <header class="sub-header">
    <p class="title">
      About the Layout:<span><slot name="title"></slot></span>
    </p>
    <p class="description"><slot name="description"></slot></p>
  </header>
</template>

<style scoped lang="scss">
header.sub-header {
  padding: 3.3rem 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 14px;
  .title {
    font-size: 18px;
    line-height: 28px;
    span {
      margin-left: 6px;
      color: #00206f;
      font-weight: 700;
    }
  }
  .description {
    max-width: 971px;
  }
}
</style>
