<template>
  <!-- Next component should appear on admin only -->
  <StandardAdminPage v-if="!dataStore.storefrontMode" />
  <GridTemplates />
</template>

<script setup>
import StandardAdminPage from "@/components/Admin/LayoutPages/StandardAdminPage";
import GridTemplates from "@/components/StylingTemplates/GridTemplates.vue";

import { onMounted } from "vue";
import appDataStore from "@/stores/appDataStore";

const dataStore = appDataStore();

// get media data
onMounted(() => {
  if (dataStore.storefrontMode) {
    let output = dataStore.responsiveCheck();
    console.log(output);
  }

  dataStore.getMediaData(
    `https://ipfeed-api.numinix.com/api/return-account-type-data?limit=${
      dataStore.settings.columns * dataStore.settings.rows
    }`,
    "POST"
  );
});
</script>
