import { defineStore } from "pinia";
import { ref } from "vue";
import helpers from "@/services/helpers";

const ADMIN_URL = "https://ipfeed-api.numinix.com/api";

const mediaArray = ref([]);
const initialLoading = ref(true);
const subsequentLoading = ref(false);

const appDataStore = defineStore("appData", {
  state: () => {
    return {
      windowWidth: null,
      /* 
      Next state can make project render as Admin or as storefront:
        1-true: storefront project.
        2-false: Admin project.
      */
      storefrontMode: process.env.VUE_APP_STOREFRONT === "true" ? true : false,
      /*
      Next state should be used if the project running as storefront
      in storefront noneed for beared token, we only need store_id
      */
      lexmodoStoreId: null,
      //authentication data
      isUserAuthenticated: false,
      //user authentication opions are: "personal(instagram)" , "business(facebook)"
      userAuthType: "",
      loadingConnectCheck: false,
      mediaArray,
      initialLoading,
      subsequentLoading,
      mediaArrayLoading: false,
      timer: null,
      counter: 0,
      // template options are : "standard", "custom","highlight","carousel","scroll"
      selectedTemplate: "",
      nextUrl: "",
      previousUrl: "",
      // settings default: {length: 4, width: 2}
      settings: {},
      // dataTypes: is the type of data source, whether it comes from the user account or hashtag keyword
      // "default": data will be retrieved from user instagram account
      // "hashtag": data will be retrieved from searching
      dataType: "",
      searchedKeywords: [],
      lastSearchedKeyword: "",
    };
  },
  getters: {
    minimumImageHeight() {
      if (this.windowWidth) {
        let width =
          this.windowWidth / this.settings.columns - 20 * this.settings.columns;

        return width + "px";
      } else {
        return null;
      }
    },
  },
  actions: {
    //function to get store_id from storefront
    getStoreId() {
      let appDiv = document.getElementById("app-lexmodo-photos");
      this.lexmodoStoreId = +appDiv.getAttribute("data-store-id");
      if (
        process.env.VUE_APP_STOREFRONT_STORE_ID_DEVELOPEMENT_MODE !== "DYNAMIC"
      ) {
        this.lexmodoStoreId =
          +process.env.VUE_APP_STOREFRONT_STORE_ID_DEVELOPEMENT_MODE;
        console.log("development_mode");
      }
      // console.log(this.lexmodoStoreId);
    },

    setTemplate(templateName) {
      this.selectedTemplate = templateName;
      helpers.sendRequest("return-account-type", "post", {
        style: templateName,
      });
    },
    //function that save setting in pinia store and in server
    setSettings(settings = {}) {
      this.settings = settings;
      this.saveSettingsInServer(settings);
    },
    //function that save setting in server only
    saveSettingsInServer(settingsData) {
      helpers.sendRequest("return-account-type", "post", {
        length: settingsData.length,
        width: settingsData.width,
      });
    },
    //function that get media data from server and set next and previous links.
    getMediaData(url, method) {
      this.mediaArrayLoading = true;

      // authenticate with store_id instead of bearer token
      if (this.storefrontMode) {
        let addedQueryString = url.includes("?")
          ? `&store_id=${this.lexmodoStoreId}`
          : `?store_id=${this.lexmodoStoreId}`;
        url += addedQueryString;
      }
      let requestHeader = this.storefrontMode
        ? helpers.getHeaderNoAuth()
        : helpers.getHeader();

      fetch(url, { method: method, headers: requestHeader })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("dataObject ", data);
          if (data.status === "success" || data.data) {
            let dataObject;

            if (data.data) {
              dataObject = data;
            }
            if (data.status === "success") {
              dataObject = data.payload;
            }
            console.log("dataObject data ", dataObject.data);
            this.mediaArray = dataObject.data;
            this.nextUrl = dataObject.paging.next;
            this.previousUrl = dataObject.paging.previous;
            this.mediaArrayLoading = false;
            console.log("mediaArray", this.mediaArray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getScrollMediaData(url, method) {
      this.subsequentLoading = true;

      // authenticate with store_id instead of bearer token
      if (this.storefrontMode) {
        let addedQueryString = url.includes("?")
          ? `&store_id=${this.lexmodoStoreId}`
          : `?store_id=${this.lexmodoStoreId}`;
        url += addedQueryString;
      }
      let requestHeader = this.storefrontMode
        ? helpers.getHeaderNoAuth()
        : helpers.getHeader();

      fetch(url, { method: method, headers: requestHeader })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("dataObject ", data);
          if (data.status === "success" || data.data) {
            let dataObject;

            if (data.data) {
              dataObject = data;
            }
            if (data.status === "success") {
              dataObject = data.payload;
            }
            console.log("dataObject data ", dataObject.data);
            this.mediaArray = [...this.mediaArray, ...dataObject.data];
            this.nextUrl = dataObject.paging.next;
            this.previousUrl = dataObject.paging.previous;
            this.subsequentLoading = false;
            if (this.initialLoading) {
              this.initialLoading = false;
            }
            console.log("mediaArray", this.mediaArray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // authentication methods
    authenticateUser(userAuthType) {
      // clear any setted timers to prevent user from multiple checking requests.
      clearInterval(this.timer);
      helpers
        .sendRequest(`change-account-type?new_type=${userAuthType}`, "post")
        .then((data) => {
          if (data.status === "failed") {
            window.open(data.payload.connection_link.url);
            this.checkUserTypeSetInterval();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /*
       this method run: - when app is mounted for first time to intialize user data.
                        - also run intervally to check if user is logged in.
       based on response navigate user to connect page or to his customization page.
       1- empty response => connect page
       2- "personal" => customization page(type is personal), reset timer
       3- "business" => customization page(type is business), reset timer
    */
    selectUser(data) {
      console.log("selectUser Data", data);
      this.userAuthType = data[0];
      this.isUserAuthenticated = true;
      this.selectedTemplate = data[1].payload.style;
      this.dataType = data[1].payload.dataType;

      clearInterval(this.timer);
      this.counter = 0;
      // if datatype is hashtag
      if (this.dataType === "hashtag") {
        this.getSearchKeywords();
      }
      // custom layout doesn't have fixed dimensions.
      // if layout is not custom
      if (data[1].payload.style !== "custom") {
        if (data[1].payload.dimensions === null) {
          this.setSettings({ rows: 2, columns: 4 });
        } else {
          this.settings.rows = +data[1].payload.dimensions.width;
          this.settings.columns = +data[1].payload.dimensions.length;
        }
      } else {
        this.setSettings({ columns: 3, rows: 4 });
      }
    },
    //
    checkUserType(noLoading = false) {
      this.loadingConnectCheck = !noLoading;

      helpers
        .sendRequest("return-account-type", "post", {}, this.lexmodoStoreId)
        .then((data) => {
          this.loadingConnectCheck = false;
          switch (data[0]) {
            case "":
              this.isUserAuthenticated = false;
              this.userAuthType = "";
              break;
            case "personal":
              this.selectUser(data);
              break;
            case "business":
              this.selectUser(data);
              break;
            default:
              break;
          }
        });
    },
    //this method will repeat a request every 3 seconds
    //you should clear the timer if the response is as expected ex:(user is logged in)
    checkUserTypeSetInterval() {
      // debouncing to prevent many request
      // if (this.timer !== null) {
      //   clearInterval(this.timer);
      // }
      this.timer = setInterval(() => {
        this.counter += 3000;
        this.checkUserType(true);
      }, 3000);
    },
    // this method will clear user data and navigate user to the connect page
    switchAccountType() {
      let accountType =
        this.userAuthType === "personal" ? "business" : "personal";
      helpers.sendRequest("change-account-type", "post", {
        new_type: accountType,
      });
      this.isUserAuthenticated = false;
      this.userAuthType = "";
      this.settings = {};
    },
    getSearchKeywords() {
      helpers
        .sendRequest("recentlySearchedHashtags?view=Top", "get")
        .then((data) => {
          if (data.status === "success") {
            this.searchedKeywords = data.payload.hashtag;
            this.lastSearchedKeyword = data.payload.hashtag[0].name;
          }
        });
    },
    //add a method to add search keyword, dataType => hashtag, get media data with this keyword
    changeDataType(dataType) {
      this.dataType = dataType;
      helpers.sendRequest(
        `${ADMIN_URL}/return-account-type${dataType}`,
        "post"
      );
    },
    // deleteUserData function
    removeUserData() {
      const sentData = {
        account_type: this.userAuthType,
      };
      helpers.sendRequest("deleteUserData", "post", sentData).then(() => {
        // console.log("user data has been deleted");
        // console.log(data);
        // console.log("redirect to back to admin plugins");
        // console.log(window.location.href);
        // console.log(window.location.pathname);
        // window.top.location.href = "https://admin.lexmodo.com/plugins";
        this.isUserAuthenticated = false;
        this.userAuthType = "";
        this.settings = {};
      });
    },
    // this function should return the num of column and rows to handle number of fetched images
    responsiveCheck() {
      // calculate the width of app
      let appWidth = +getComputedStyle(
        document.querySelector(".menu-tab")
      ).width.replace("px", "");
      // console.log(appWidth);

      if (appWidth < 760 && appWidth > 660) {
        // this.setSettings({ ...this.settings, columns: 3 });
        this.settings = { ...this.settings, columns: 3 };
      } else if (appWidth < 660 && appWidth > 430) {
        // this.setSettings({ ...this.settings, columns: 2 });
        this.settings = { ...this.settings, columns: 2 };
      } else if (appWidth < 430) {
        if (
          this.selectedTemplate !== "carousel" &&
          this.selectedTemplate !== "scroll"
        ) {
          this.selectedTemplate = "carousel";
        }
        // this.setSettings({ rows: 2, columns: 1 });
        this.settings = { rows: 2, columns: 1 };
      } else if (appWidth > 760) {
        // this.setSettings({ ...this.settings, columns: 3 });
        this.settings = { ...this.settings, columns: 3 };
      }
      return { ...this.settings };
    },
  },
});

export default appDataStore;
